<template>
  <Form @submit="save">
    <v-row>
      <v-col>
        <v-breadcrumbs :items="breadcrumbs" class="ma-0 pa-0" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h1>{{ this.uuid ? 'Editar' : 'Agregar' }} producto</h1>
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <SelectField
          v-model="form.service_id"
          :items="services"
          item-text="name"
          item-value="id"
          label="Servicio *"
          prepend-icon="mdi-format-list-bulleted"
          rules="required"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <TextField
          v-model="form.name"
          label="Nombre *"
          prepend-icon="mdi-hanger"
          rules="required"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <TextField
          v-model="form.key"
          counter="5"
          maxlength="5"
          label="Clave *"
          prepend-icon="mdi-format-text"
          rules="required|max:5"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <TextField
          v-model="form.unit_price_normal"
          min="1"
          step="0.01"
          type="number"
          label="Costo unitario normal *"
          prepend-icon="mdi-currency-usd"
          rules="required|min_value:1"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <TextField
          v-model="form.unit_price_urgent"
          min="1"
          step="0.01"
          type="number"
          label="Costo unitario urgente *"
          prepend-icon="mdi-currency-usd"
          rules="required|min_value:1"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <TextField
          v-model="form.clothes_quantity"
          min="1"
          type="number"
          label="Cantidad de prendas *"
          prepend-icon="mdi-pound"
          rules="required|min_value:1"
        />
      </v-col>

      <v-col cols="12" class="text-center">
        <v-btn color="primary" type="submit">
          <v-icon left>mdi-content-save</v-icon>
          Guardar
        </v-btn>
      </v-col>
    </v-row>
  </Form>
</template>

<script>
import ProductAPI from '@/api/product';

export default {
  name: 'ProductForm',

  props: {
    uuid: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    form: {
      clothes_quantity: null,
      key: null,
      name: null,
      service_id: null,
      unit_price: null,
    },
    services: [],
  }),

  async created() {
    this.api = new ProductAPI();
    await this.getServices();

    if (this.uuid) {
      await this.detail();
    }
  },

  computed: {
    breadcrumbs() {
      const items = [
        {
          text: 'Productos',
          disabled: false,
          exact: true,
          to: { name: 'product-list' },
        },
      ];

      if (this.uuid) {
        const name = this.form.name || '';
        items.push(
          {
            text: name.toUpperCase(),
            disabled: false,
            exact: true,
            to: { name: 'product-detail', params: { uuid: this.uuid } },
          },
          {
            text: 'Editar',
            disabled: true,
          },
        );
      } else {
        items.push({
          text: 'Agregar',
          disabled: true,
        });
      }

      return items;
    },
  },

  methods: {
    async save() {
      this.$store.commit('showLoader');
      try {
        if (this.uuid) {
          await this.api.update(this.uuid, this.form);
          this.$router.push({ name: 'product-detail', params: { uuid: this.uuid } });
        } else {
          await this.api.create(this.form);
          this.$router.push({ name: 'product-list' });
        }

        this.$store.dispatch('notification/notifySuccess');
      } catch (error) {
        this.$store.dispatch('notification/notifyError', 'No se pudieron guardar los datos.');
      }
      this.$store.commit('hideLoader');
    },

    async detail() {
      this.$store.commit('showLoader');
      try {
        this.form = await this.api.detail(this.uuid);
        this.form.service_id = this.form.service.id;
      } catch (error) {
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },

    async getServices() {
      this.$store.commit('showLoader');
      try {
        const res = await this.api.services();
        this.services = res.results;
      } catch (error) {
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },
  },
};
</script>

<style lang="sass" scoped>

</style>
